import React from 'react'
import Repayment from './Repayment'

const Repayments = ({ repayments, theme }) => {
  return (
    repayments.map(repayment => <Repayment {...repayment} theme={theme} />)
  )
}

export default Repayments
