import React from 'react'
import Repayments from './components/Repayments/Repayments'

const RepaymentsPage = (props) => {
  const repayments = [
    { amount: "₹  1,000", date: "May 2021", type: "success" },
    { amount: "₹  1,000", date: "May 2021", type: "success" },
    { amount: "₹  1,000", date: "May 2021", type: "late" },
    { amount: "₹  1,000", date: "May 2021", type: "not due" }
  ]

  return (
    <div>
      <div className={`bg-${props.theme}-500 p-10`}>
        <div className="flex items-center justify-center w-full mx-auto space-x-3">
          <div className="flex flex-col text-center">
            <span className="text-white mb-5 text-center text-lg">Your credit limit is</span>
            <span className="text-white mb-5 text-center text-4xl font-bold"> ₹ 15,000</span>
          </div>
        </div>
      </div>
      <div className={`px-4 my-4 text-extrabold`}>
        <p>Repayments</p>
        <div className={`pt-4 px-4 mt-2 shadow-md border border-opacity-50`}>
          <div className={`flex justify-around mb-2`}>
            <div className={`flex-1 text-left`}>Amount</div>
            <div className={`flex-1 text-left`}>Paid</div>
          </div>
          <Repayments repayments={repayments} {...props} />
        </div>
      </div>
    </div>
  )
}

export default RepaymentsPage
