import React from "react";
import Routes from "./Routes";
import Axios from "axios";
import ReactGA from "react-ga";
ReactGA.initialize(process.env.REACT_APP_GA_KEY);
ReactGA.pageview(window.location.pathname + window.location.search);

Axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

const App = () => {
  return <Routes />;
};

export default App;
