import React from 'react'

const Screen2 = (props) => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="text=black text-center py-8">
        Complete your KYC to avail credit limit upto Rs. 15000
      </div>
      <div className="m-auto w-11/12 bg-white rounded-md p-5">
        <div className="text-black-900 flex justify-between px-5">
          <div className="font-normal">PROOF OF IDENTITY</div>
          <div className="font-normal"></div>
        </div>
        <div className="text-black-900 px-5 my-6 text-gray-500">
          <div className="text-md">Enter PAN no.</div>
          <div className="flex flex-row">
            <input type="text" className="flex-1 bg-gray-100 h-10 rounded-sm w-6 mr-1 mt-2 focus:bg-white" />
            <input type="text" className="flex-1 bg-gray-100 h-10 rounded-sm w-6 mr-1 mt-2 focus:bg-white" />
            <input type="text" className="flex-1 bg-gray-100 h-10 rounded-sm w-6 mr-1 mt-2 focus:bg-white" />
            <input type="text" className="flex-1 bg-gray-100 h-10 rounded-sm w-6 mr-1 mt-2 focus:bg-white" />
            <input type="text" className="flex-1 bg-gray-100 h-10 rounded-sm w-6 mr-1 mt-2 focus:bg-white" />
            <input type="text" className="flex-1 bg-gray-100 h-10 rounded-sm w-6 mr-1 mt-2 focus:bg-white" />
            <input type="text" className="flex-1 bg-gray-100 h-10 rounded-sm w-6 mr-1 mt-2 focus:bg-white" />
            <input type="text" className="flex-1 bg-gray-100 h-10 rounded-sm w-6 mr-1 mt-2 focus:bg-white" />
          </div>
        </div>
        <div className="px-5 my-6 text-gray-500">
          <div className="text-md">Enter Date Of Birth</div>
          <div className="flex flex-row">
            <input type="date" className="flex-1 bg-gray-100 h-10 rounded-sm p-2 mt-2 focus:bg-white" />
          </div>
        </div>
        <div className="px-5 my-6 text-gray-500">
          <div className="text-md">Upload PAN</div>
          <div className="flex flex-row">
            <div className="flex-1 bg-gray-400 h-10 rounded-md p-2 mt-2 focus:bg-white text-center text-white font-bold">
              Upload your PAN card Photo
            </div>
          </div>
        </div>
        <div className="text-black-900 px-5 my-6 text-gray-900 flex">
          <input type="checkbox" name="" id="" className="mt-2" />
          <div className="flex flex-row p=0 ml-2">
            <div className="flex-1 text-gray-400 rounded-sm focus:bg-white font-xs">
              I hereby appoint Fintech as my authorised representative to receive my credit information from Cibil / Equifax / Experian / CRIF Highmark (bureau)
            </div>
          </div>
        </div>
        <div className="px-5 my-6 text-gray-900 flex">
          <button className={`bg-${props.theme}-500 py-2 px-4 w-full rounded-md text-white`}>Next</button>
        </div>
      </div>
    </div>
  )
}

export default Screen2
