import React from "react";
import PropTypes from  "prop-types";

function LightHeroE(props) {
  return (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 pt-24 md:flex-row flex-col items-center">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <img className="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" />
          
        </div>
        <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            Verify Income
          </h1>
          <p className="mb-8 leading-relaxed">
            You are pre-approved for a loan upto <br className="hidden lg:inline-block" />
            for Rs. 1,00,000<small>.00</small>
          </p>
          <p className="mb-8 leading-relaxed">
            Drop your bank account statement into the box on the left.
          </p>
          <div class="container m-2">
            <p className="text-sm mt-2 text-gray-500 mb-8 w-full">
              Want to use a Net banking?
            </p>
         
          </div>
        </div>
      </div>
      <div class="container mx-auto my-5 px-5 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg flex lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <button className={`w-1/2 text-white bg-${props.theme}-500 border-0 py-2 px-6 focus:outline-none hover:bg-${props.theme}-600 rounded text-lg`}>
                Upload
            </button>
            <button className="flex-grow ml-3 text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">
                or Net Banking
            </button>
          </div>
      </div>
    </section>
  );
}

LightHeroE.defaultProps = {
  theme: 'indigo'
};

LightHeroE.propTypes = {
  theme: PropTypes.string.isRequired
};

export default LightHeroE;
