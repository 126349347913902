import React from 'react';
import PropTypes from  "prop-types";

function LightEMID(props) {
  return (
    <section className="text-gray-600 body-font overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        <div className="-my-8 divide-y-2 divide-gray-100">
          <div className="py-8 flex flex-wrap md:flex-nowrap">
            <div className="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
              <span className="font-semibold title-font text-gray-700">
                CREDIT
              </span>
              <span className="mt-1 text-gray-500 text-sm">12 Jun 2021</span>
            </div>
            <div className="md:flex-grow">
              <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">
                Your credit limit was increased by Rs. 1000<small>.00</small>
              </h2>
              <p className="leading-relaxed">
                You earned 2 points.
              </p>
              <a href className={`text-${props.theme}-500 inline-flex items-center mt-4`}>
                View Details
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
          <div className="py-8 flex flex-wrap md:flex-nowrap">
            <div className="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
              <span className="font-semibold title-font text-gray-700">
                REBALANCE
              </span>
              <span className="mt-1 text-gray-500 text-sm">12 Jun 2021</span>
            </div>
            <div className="md:flex-grow">
              <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">
                You split your last transaction into 2 EMI's
              </h2>
              <p className="leading-relaxed">
                You have one more split bill option this month.
              </p>
              <a href className={`text-${props.theme}-500 inline-flex items-center mt-4`}>
                Learn More
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
          <div className="py-8 flex flex-wrap md:flex-nowrap">
            <div className="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
              <span className="font-semibold title-font text-gray-700">
                DEBIT
              </span>
              <span className="text-sm text-gray-500">12 Jun 2019</span>
            </div>
            <div className="md:flex-grow">
              <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">
                You used Rs. 4000<small>.00</small> from your credit limit
              </h2>
              <p className="leading-relaxed">
                Your balance is now Rs. 0
              </p>
              <a href className={`text-${props.theme}-500 inline-flex items-center mt-4`}>
                View details
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

LightEMID.defaultProps = {
  theme: 'indigo'
};

LightEMID.propTypes = {
  theme: PropTypes.string.isRequired
};

export default LightEMID;
