import React from 'react'
import { TiTick } from "react-icons/ti";

function BoxDetail(props) {
    const { title, message } = props;
    return (
      <div  style={{ margin: "20px 20px" }}>
          <div className="flex align-column lg:text-center md:text-center"  >
              <div className="fill-current text-blue-400 ">
              <TiTick/>
              </div>
          <h4 className="text-lg font-bold" style={{ textAlign: "left" }}>
          {title}   
        </h4>
          </div>
        <p className="text-lg ml-5 font-light"  style={{ textAlign: "left"}}>{message}</p>
      </div>
    );
}

export default BoxDetail
