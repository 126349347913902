import LightEMIA from "./blocks/emi/light/a";
import LightEMIB from "./blocks/emi/light/b";
import LightEMIC from "./blocks/emi/light/c";
import LightEMID from "./blocks/emi/light/d";
import LightEMIE from "./blocks/emi/light/e";

import LightMinimumA from "./blocks/minimum/light/a";
import LightMinimumB from "./blocks/minimum/light/b";
import LightMinimumC from "./blocks/minimum/light/c";
import LightMinimumD from "./blocks/minimum/light/d";
import LightMinimumE from "./blocks/minimum/light/e";

import LightUploadDocumentsG from "./blocks/uploadDocuments/light/g";

import LightBorrowerDetailsA from "./blocks/borrowerDetails/light/a";

import LightContactA from "./blocks/contact/light/a";
import LightContactB from "./blocks/contact/light/b";
import LightContactC from "./blocks/contact/light/c";
import LightContactD from "./blocks/contact/light/d";

import LightContentA from "./blocks/content/light/a";
import LightContentB from "./blocks/content/light/b";
import LightContentC from "./blocks/content/light/c";
import LightContentD from "./blocks/content/light/d";
import LightContentE from "./blocks/content/light/e";
import LightContentF from "./blocks/content/light/f";
import LightContentG from "./blocks/content/light/g";
import LightContentH from "./blocks/content/light/h";

import LightCTAA from "./blocks/cta/light/a";
import LightCTAB from "./blocks/cta/light/b";
import LightCTAC from "./blocks/cta/light/c";
import LightCTAD from "./blocks/cta/light/d";

import LightEcommerceA from "./blocks/ecommerce/light/a";
import LightEcommerceB from "./blocks/ecommerce/light/b";
import LightEcommerceC from "./blocks/ecommerce/light/c";

import LightFeatureA from "./blocks/feature/light/a";
import LightFeatureB from "./blocks/feature/light/b";
import LightFeatureC from "./blocks/feature/light/c";
import LightFeatureD from "./blocks/feature/light/d";
import LightFeatureE from "./blocks/feature/light/e";
import LightFeatureF from "./blocks/feature/light/f";
import LightFeatureG from "./blocks/feature/light/g";
import LightFeatureH from "./blocks/feature/light/h";

import LightFooterA from "./blocks/footer/light/a";
import LightFooterB from "./blocks/footer/light/b";
import LightFooterC from "./blocks/footer/light/c";
import LightFooterD from "./blocks/footer/light/d";
import LightFooterE from "./blocks/footer/light/e";

import LightGalleryA from "./blocks/gallery/light/a";
import LightGalleryB from "./blocks/gallery/light/b";
import LightGalleryC from "./blocks/gallery/light/c";

import LightHeaderA from "./blocks/header/light/a";
import LightHeaderB from "./blocks/header/light/b";
import LightHeaderC from "./blocks/header/light/c";
import LightHeaderD from "./blocks/header/light/d";

import LightHeroA from "./blocks/hero/light/a";
import LightHeroB from "./blocks/hero/light/b";
import LightHeroC from "./blocks/hero/light/c";
import LightHeroE from "./blocks/hero/light/e";
import LightHeroF from "./blocks/hero/light/f";

import LightPricingA from "./blocks/pricing/light/a";
import LightPricingB from "./blocks/pricing/light/b";

import LightStatisticA from "./blocks/statistic/light/a";
import LightStatisticB from "./blocks/statistic/light/b";
import LightStatisticC from "./blocks/statistic/light/c";

import LightStepA from "./blocks/step/light/a";
import LightStepB from "./blocks/step/light/b";
import LightStepC from "./blocks/step/light/c";

import LightTeamA from "./blocks/team/light/a";
import LightTeamB from "./blocks/team/light/b";
import LightTeamC from "./blocks/team/light/c";

import LightTestimonialA from "./blocks/testimonial/light/a";
import LightTestimonialB from "./blocks/testimonial/light/b";
import LightTestimonialC from "./blocks/testimonial/light/c";

import AA1 from "./blocks/aa/light/a";

import EMIA from "./icons/emi/a";
import EMIB from "./icons/emi/b";
import EMIC from "./icons/emi/c";
import EMID from "./icons/emi/d";
import EMIE from "./icons/emi/e";

import MinimumA from "./icons/minimum/a";
import MinimumB from "./icons/minimum/b";
import MinimumC from "./icons/minimum/c";
import MinimumD from "./icons/minimum/d";
import MinimumE from "./icons/minimum/e";

import UploadDocumentsA from "./icons/uploadDocuments/a";

import BorrowerDetailsA from "./icons/borrowerDetails/a";

import ContactA from "./icons/contact/a";
import ContactB from "./icons/contact/b";
import ContactC from "./icons/contact/c";

import ContentA from "./icons/content/a";
import ContentB from "./icons/content/b";
import ContentC from "./icons/content/c";
import ContentD from "./icons/content/d";
import ContentE from "./icons/content/e";
import ContentF from "./icons/content/f";
import ContentG from "./icons/content/g";
import ContentH from "./icons/content/h";

import CTAA from "./icons/cta/a";
import CTAB from "./icons/cta/b";
import CTAC from "./icons/cta/c";
import CTAD from "./icons/cta/d";

import EcommerceA from "./icons/ecommerce/a";
import EcommerceB from "./icons/ecommerce/b";
import EcommerceC from "./icons/ecommerce/c";

import FeatureA from "./icons/feature/a";
import FeatureB from "./icons/feature/b";
import FeatureC from "./icons/feature/c";
import FeatureD from "./icons/feature/d";
import FeatureE from "./icons/feature/e";
import FeatureF from "./icons/feature/f";
import FeatureG from "./icons/feature/g";
import FeatureH from "./icons/feature/h";

import FooterA from "./icons/footer/a";
import FooterB from "./icons/footer/b";
import FooterC from "./icons/footer/c";
import FooterD from "./icons/footer/d";
import FooterE from "./icons/footer/e";

import GalleryA from "./icons/gallery/a";
import GalleryB from "./icons/gallery/b";
import GalleryC from "./icons/gallery/c";

import HeaderA from "./icons/header/a";
import HeaderB from "./icons/header/b";
import HeaderC from "./icons/header/c";
import HeaderD from "./icons/header/d";

import HeroA from "./icons/hero/a";
import HeroB from "./icons/hero/b";
import HeroC from "./icons/hero/c";
import HeroD from "./icons/hero/d";
import HeroE from "./icons/hero/e";
import HeroF from "./icons/hero/f";

import PricingA from "./icons/pricing/a";
import PricingB from "./icons/pricing/b";

import StatisticA from "./icons/statistic/a";
import StatisticB from "./icons/statistic/b";
import StatisticC from "./icons/statistic/c";

import StepA from "./icons/step/a";
import StepB from "./icons/step/b";
import StepC from "./icons/step/c";

import TeamA from "./icons/team/a";
import TeamB from "./icons/team/b";
import TeamC from "./icons/team/c";

import TestimonialA from "./icons/testimonial/a";
import TestimonialB from "./icons/testimonial/b";
import TestimonialC from "./icons/testimonial/c";
import Screen1 from "./blocks/sdk/Screen1";
import Screen2 from "./blocks/sdk/Screen2";
import Screen3 from "./blocks/sdk/Screen3";
import Screen4 from "./blocks/sdk/Screen4";
import Congratulations from "./blocks/sdk/Congratulations";
import Decline from "./blocks/sdk/Decline";
import Repayments from "./blocks/sdk/Repayments";
import VerifyDetails from "./blocks/sdk/VerifyDetails";
import Consent from "./blocks/consent/Consent";
import Bank from "./blocks/Bank/Bank";
import Cart from "./blocks/cart/Cart";
import Products from "./blocks/cart/Products";
import SignIn from "./blocks/consent/SignIn";

export const Sections = {
  Intro: "Intro",
  "Verify Phone": "Verify Phone",
  "Entered phone": "Entered phone",
  "Pre-Approved": "Pre-Approved",
  "More info": "More info",
  "Credit Line Ready": "Credit Line Ready",
  EMI: "EMI",
  Contact: "Contact",
  Content: "Content",
  Ecommerce: "Ecommerce",
  Feature: "Feature",
  Header: "Header",
  Footer: "Footer",
  Gallery: "Gallery",
  Pricing: "Pricing",
  Statistic: "Statistic",
  Team: "Team",
  Testimonial: "Testimonial",
  SDK: "SDK",
  Consent: "Consent",
  Cart: "Cart",
};

export const Blocks = {
  LightHeaderA,
  LightHeaderB,
  LightHeaderC,
  LightHeaderD,
  LightHeroA,
  LightHeroB,
  LightHeroC,
  // LightHeroD,
  LightHeroE,
  LightHeroF,
  LightMinimumA,
  LightMinimumB,
  LightMinimumC,
  LightMinimumD,
  LightMinimumE,
  LightUploadDocumentsG,
  LightBorrowerDetailsA,
  LightEMIA,
  LightEMIB,
  LightEMIC,
  LightEMID,
  LightEMIE,
  LightContactA,
  LightContactB,
  LightContactC,
  LightContactD,
  LightContentA,
  LightContentB,
  LightContentC,
  LightContentD,
  LightContentE,
  LightContentF,
  LightContentG,
  LightContentH,
  LightCTAA,
  LightCTAB,
  LightCTAC,
  LightCTAD,
  LightEcommerceA,
  LightEcommerceB,
  LightEcommerceC,
  LightFeatureA,
  LightFeatureB,
  LightFeatureC,
  LightFeatureD,
  LightFeatureE,
  LightFeatureF,
  LightFeatureG,
  LightFeatureH,
  LightFooterA,
  LightFooterB,
  LightFooterC,
  LightFooterD,
  LightFooterE,
  LightGalleryA,
  LightGalleryB,
  LightGalleryC,
  LightPricingA,
  LightPricingB,
  LightStatisticA,
  LightStatisticB,
  LightStatisticC,
  LightStepA,
  LightStepB,
  LightStepC,
  LightTeamA,
  LightTeamB,
  LightTeamC,
  LightTestimonialA,
  LightTestimonialB,
  LightTestimonialC,
  AA1,
  Screen1,
  Screen2,
  Screen3,
  Screen4,
  Congratulations,
  Decline,
  Repayments,
  VerifyDetails,
  Consent,
  Bank,
  Cart,
  Products,
  SignIn,
};

export const Icons = {
  HeaderA,
  HeaderB,
  HeaderC,
  HeaderD,
  HeroA,
  HeroB,
  HeroC,
  HeroD,
  HeroE,
  HeroF,
  MinimumA,
  MinimumB,
  MinimumC,
  MinimumD,
  MinimumE,
  UploadDocumentsA,
  BorrowerDetailsA,
  EMIA,
  EMIB,
  EMIC,
  EMID,
  EMIE,
  ContactA,
  ContactB,
  ContactC,
  ContentB,
  ContentA,
  ContentC,
  ContentD,
  ContentE,
  ContentF,
  ContentG,
  ContentH,
  CTAA,
  CTAB,
  CTAC,
  CTAD,
  EcommerceA,
  EcommerceB,
  EcommerceC,
  FeatureA,
  FeatureB,
  FeatureC,
  FeatureD,
  FeatureE,
  FeatureF,
  FeatureG,
  FeatureH,
  FooterA,
  FooterB,
  FooterC,
  FooterD,
  FooterE,
  GalleryA,
  GalleryB,
  GalleryC,
  PricingA,
  PricingB,
  StatisticA,
  StatisticB,
  StatisticC,
  StepA,
  StepB,
  StepC,
  TeamA,
  TeamB,
  TeamC,
  TestimonialA,
  TestimonialB,
  TestimonialC,
};

const iconBlockSectionMappingList = [
  { sectionName: Sections["SDK"], iconName: "HeroA", blockName: "Screen1" },
  { sectionName: Sections["SDK"], iconName: "HeroA", blockName: "Screen2" },
  { sectionName: Sections["SDK"], iconName: "HeroA", blockName: "Screen3" },
  { sectionName: Sections["SDK"], iconName: "HeroA", blockName: "Screen4" },
  { sectionName: Sections["SDK"], iconName: "HeroA", blockName: "Decline" },
  {
    sectionName: Sections["SDK"],
    iconName: "HeroA",
    blockName: "VerifyDetails",
  },
  {
    sectionName: Sections["SDK"],
    iconName: "HeroA",
    blockName: "Congratulations",
  },
  { sectionName: Sections["SDK"], iconName: "HeroA", blockName: "Repayments" },
  { sectionName: Sections["Header"], iconName: "HeaderA", blockName: "AA1" },
  {
    sectionName: Sections["Header"],
    iconName: "HeaderA",
    blockName: "LightHeaderA",
  },
  {
    sectionName: Sections["Header"],
    iconName: "HeaderB",
    blockName: "LightHeaderB",
  },
  {
    sectionName: Sections["Header"],
    iconName: "HeaderC",
    blockName: "LightHeaderC",
  },
  {
    sectionName: Sections["Header"],
    iconName: "HeaderD",
    blockName: "LightHeaderD",
  },
  { sectionName: Sections["Header"], iconName: "CTAA", blockName: "LightCTAA" },
  {
    sectionName: Sections["Intro"],
    iconName: "MinimumB",
    blockName: "LightMinimumB",
  },
  { sectionName: Sections["Intro"], iconName: "CTAC", blockName: "LightCTAC" },
  { sectionName: Sections["Intro"], iconName: "CTAB", blockName: "LightCTAB" },
  {
    sectionName: Sections["Verify Phone"],
    iconName: "CTAD",
    blockName: "LightCTAD",
  },
  {
    sectionName: Sections["Entered phone"],
    iconName: "ContentH",
    blockName: "LightContentH",
  },
  {
    sectionName: Sections["Entered phone"],
    iconName: "HeroD",
    blockName: "LightHeroD",
  },
  {
    sectionName: Sections["Entered phone"],
    iconName: "HeroE",
    blockName: "LightHeroE",
  },
  {
    sectionName: Sections["Entered phone"],
    iconName: "HeroF",
    blockName: "LightHeroF",
  },
  {
    sectionName: Sections["Pre-Approved"],
    iconName: "HeroA",
    blockName: "LightHeroA",
  },
  {
    sectionName: Sections["Pre-Approved"],
    iconName: "HeroB",
    blockName: "LightHeroB",
  },
  {
    sectionName: Sections["Pre-Approved"],
    iconName: "HeroC",
    blockName: "LightHeroC",
  },
  {
    sectionName: Sections["More info"],
    iconName: "MinimumA",
    blockName: "LightMinimumA",
  },
  {
    sectionName: Sections["More info"],
    iconName: "UploadDocumentsA",
    blockName: "LightUploadDocumentsG",
  },
  {
    sectionName: Sections["More info"],
    iconName: "MinimumC",
    blockName: "LightMinimumC",
  },
  {
    sectionName: Sections["More info"],
    iconName: "MinimumE",
    blockName: "LightMinimumE",
  },
  {
    sectionName: Sections["More info"],
    iconName: "BorrowerDetailsA",
    blockName: "LightBorrowerDetailsA",
  },
  {
    sectionName: Sections["Credit Line Ready"],
    iconName: "StepA",
    blockName: "LightStepA",
  },
  {
    sectionName: Sections["Credit Line Ready"],
    iconName: "EMIB",
    blockName: "LightEMIB",
  },
  {
    sectionName: Sections["Credit Line Ready"],
    iconName: "EMIA",
    blockName: "LightEMIA",
  },
  {
    sectionName: Sections["Credit Line Ready"],
    iconName: "MinimumD",
    blockName: "LightMinimumD",
  },
  { sectionName: Sections["EMI"], iconName: "EMIC", blockName: "LightEMIC" },
  { sectionName: Sections["EMI"], iconName: "EMIE", blockName: "LightEMIE" },
  {
    sectionName: Sections["Contact"],
    iconName: "ContactA",
    blockName: "LightContactA",
  },
  {
    sectionName: Sections["Contact"],
    iconName: "ContactB",
    blockName: "LightContactB",
  },
  {
    sectionName: Sections["Contact"],
    iconName: "ContactC",
    blockName: "LightContactC",
  },
  {
    sectionName: Sections["Contact"],
    iconName: "ContactC",
    blockName: "LightContactD",
  },
  {
    sectionName: Sections["Content"],
    iconName: "ContentA",
    blockName: "LightContentA",
  },
  {
    sectionName: Sections["Content"],
    iconName: "ContentB",
    blockName: "LightContentB",
  },
  {
    sectionName: Sections["Content"],
    iconName: "ContentC",
    blockName: "LightContentC",
  },
  {
    sectionName: Sections["Content"],
    iconName: "ContentD",
    blockName: "LightContentD",
  },
  {
    sectionName: Sections["Content"],
    iconName: "ContentE",
    blockName: "LightContentE",
  },
  {
    sectionName: Sections["Content"],
    iconName: "ContentF",
    blockName: "LightContentF",
  },
  {
    sectionName: Sections["Content"],
    iconName: "ContentG",
    blockName: "LightContentG",
  },
  {
    sectionName: Sections["Content"],
    iconName: "ContentH",
    blockName: "LightContentH",
  },

  {
    sectionName: Sections["Ecommerce"],
    iconName: "EcommerceA",
    blockName: "LightEcommerceA",
  },
  {
    sectionName: Sections["Ecommerce"],
    iconName: "EcommerceB",
    blockName: "LightEcommerceB",
  },
  {
    sectionName: Sections["Ecommerce"],
    iconName: "EcommerceC",
    blockName: "LightEcommerceC",
  },
  {
    sectionName: Sections["Feature"],
    iconName: "FeatureA",
    blockName: "LightFeatureA",
  },
  {
    sectionName: Sections["Feature"],
    iconName: "FeatureB",
    blockName: "LightFeatureB",
  },
  {
    sectionName: Sections["Feature"],
    iconName: "FeatureC",
    blockName: "LightFeatureC",
  },
  {
    sectionName: Sections["Feature"],
    iconName: "FeatureD",
    blockName: "LightFeatureD",
  },
  {
    sectionName: Sections["Feature"],
    iconName: "FeatureE",
    blockName: "LightFeatureE",
  },
  {
    sectionName: Sections["Feature"],
    iconName: "FeatureF",
    blockName: "LightFeatureF",
  },
  {
    sectionName: Sections["Feature"],
    iconName: "FeatureG",
    blockName: "LightFeatureG",
  },
  {
    sectionName: Sections["Feature"],
    iconName: "FeatureH",
    blockName: "LightFeatureH",
  },
  {
    sectionName: Sections["Footer"],
    iconName: "FooterA",
    blockName: "LightFooterA",
  },
  {
    sectionName: Sections["Footer"],
    iconName: "FooterB",
    blockName: "LightFooterB",
  },
  {
    sectionName: Sections["Footer"],
    iconName: "FooterC",
    blockName: "LightFooterC",
  },
  {
    sectionName: Sections["Footer"],
    iconName: "FooterD",
    blockName: "LightFooterD",
  },
  {
    sectionName: Sections["Footer"],
    iconName: "FooterE",
    blockName: "LightFooterE",
  },
  {
    sectionName: Sections["Gallery"],
    iconName: "GalleryA",
    blockName: "LightGalleryA",
  },
  {
    sectionName: Sections["Gallery"],
    iconName: "GalleryB",
    blockName: "LightGalleryB",
  },
  {
    sectionName: Sections["Gallery"],
    iconName: "GalleryC",
    blockName: "LightGalleryC",
  },
  {
    sectionName: Sections["Pricing"],
    iconName: "PricingA",
    blockName: "LightPricingA",
  },
  {
    sectionName: Sections["Pricing"],
    iconName: "PricingB",
    blockName: "LightPricingB",
  },
  {
    sectionName: Sections["Statistic"],
    iconName: "StatisticA",
    blockName: "LightStatisticA",
  },
  {
    sectionName: Sections["Statistic"],
    iconName: "StatisticB",
    blockName: "LightStatisticB",
  },
  {
    sectionName: Sections["Statistic"],
    iconName: "StatisticC",
    blockName: "LightStatisticC",
  },
  { sectionName: Sections["Team"], iconName: "TeamA", blockName: "LightTeamA" },
  { sectionName: Sections["Team"], iconName: "TeamB", blockName: "LightTeamB" },
  { sectionName: Sections["Team"], iconName: "TeamC", blockName: "LightTeamC" },
  {
    sectionName: Sections["Testimonial"],
    iconName: "TestimonialA",
    blockName: "LightTestimonialA",
  },
  {
    sectionName: Sections["Testimonial"],
    iconName: "TestimonialB",
    blockName: "LightTestimonialB",
  },
  {
    sectionName: Sections["Testimonial"],
    iconName: "TestimonialC",
    blockName: "LightTestimonialC",
  },
  {
    sectionName: Sections["Consent"],
    iconName: "TestimonialC",
    blockName: "Consent",
  },
  { sectionName: Sections["Consent"], iconName: "HeroA", blockName: "SignIn" },
  {
    sectionName: Sections["Consent"],
    iconName: "TestimonialC",
    blockName: "Bank",
  },
  { sectionName: Sections["Cart"], iconName: "HeroA", blockName: "Products" },
  { sectionName: Sections["Cart"], iconName: "HeroA", blockName: "Cart" },
];

const getIndexOfFoundSectionName = (array, sectionName) => {
  let index = -1;
  for (let i = 0; i < array.length; i++)
    if (array[i].sectionName === sectionName) return i;
  return index;
};

export const getIconBlockMappingList = (iconBlockSectionMappingList) => {
  const DistinctSections = [];
  iconBlockSectionMappingList.forEach(
    ({ sectionName, iconName, blockName }, index) => {
      let foundIndex = getIndexOfFoundSectionName(
        DistinctSections,
        sectionName
      );
      if (foundIndex === -1) {
        DistinctSections.push({
          sectionName: sectionName,
          blocks: [{ iconName, blockName }],
        });
      } else DistinctSections[foundIndex].blocks.push({ iconName, blockName });
    }
  );
  return DistinctSections;
};

export default iconBlockSectionMappingList;
