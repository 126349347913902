import React from 'react';
import PropTypes from  "prop-types";

function LightEMIE(props) {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap -mx-4 -my-8">
          <div className="py-8 px-4 lg:w-1/2 w-1/2">
            <div className="h-full flex items-start">
              <div className="flex-shrink-0 flex flex-col text-center leading-none">
                <span className="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200">
                  Jul
                </span>
                <span className="font-medium text-lg text-gray-800 title-font leading-none">
                  18
                </span>
              </div>
              <div className="flex-grow pl-6">
                <h2 className={`tracking-widest text-xs title-font font-medium text-${props.theme}-500 mb-1`}>
                  EMI 1
                </h2>
                <h1 className="title-font text-xl font-medium text-gray-900 mb-3">
                  Rs. 15,000<small>.00</small>
                </h1>
                <p className="leading-relaxed mb-5">
                  Paid on Jul 17th
                </p>
                <a href className="inline-flex items-center">
                  <img
                    alt="blog"
                    src="https://dummyimage.com/103x103"
                    className="w-8 h-8 rounded-full flex-shrink-0 object-cover object-center"
                  />
                  <span className="flex-grow flex flex-col pl-3">
                    <span className="title-font font-medium text-gray-900">
                      Paid
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="py-8 px-4 lg:w-1/2 w-1/2">
            <div className="h-full flex items-start">
              <div className="flex-shrink-0 flex flex-col text-center leading-none">
                <span className="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200">
                  Aug
                </span>
                <span className="font-medium text-lg text-gray-800 title-font leading-none">
                  18
                </span>
              </div>
              <div className="flex-grow pl-6">
                <h2 className={`tracking-widest text-xs title-font font-medium text-${props.theme}-500 mb-1`}>
                  EMI 2
                </h2>
                <h1 className="title-font text-xl font-medium text-gray-900 mb-3">
                  Rs. 15,000<small>.00</small>
                </h1>
              
                <a href className="inline-flex items-center">
                  <img
                    alt="blog"
                    src="https://dummyimage.com/102x102"
                    className="w-8 h-8 rounded-full flex-shrink-0 object-cover object-center"
                  />
                  <span className="flex-grow flex flex-col pl-3">
                    <span className="title-font font-medium text-gray-900">
                      Unpaid
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

LightEMIE.defaultProps = {
  theme: 'indigo'
};

LightEMIE.propTypes = {
  theme: PropTypes.string.isRequired
};

export default LightEMIE;
