import React from 'react'
import { HashRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import CreateTemplate from './pages/CreateTemplate';
import Template from './pages/Template';
import TemplateByID from './pages/TemplateByID';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/defaulttemplate" component={Template} />
        <Route path="/share/:id" component={TemplateByID} />
        <Route path="/" exact component={CreateTemplate} />
        <Redirect to="/" />
      </Switch>
    </Router>
  )
}

export default Routes