import React from 'react'

const VerifyDetails = (props) => {
  return (
    <div className="bg-white min-h-screen">

      <div className="m-auto w-11/12 bg-white rounded-md py-5">
        <div className="text-md font-bold">Verifying Proof of Address</div>
      </div>

      <div className="m-auto w-11/12 bg-white rounded-xl px-5 py-3 mb-4" style={{ border: "1px solid #9A9A9A" }}>
        <div className={`px-5 py-1`}>
          <div className={`flex`}>
            <div className="flex-1 text-gray-400">Full Name</div>
            <div className="flex-1 text-gray-400">Shankar Bhosale</div>
          </div>
        </div>
        <div className={`px-5 py-1`}>
          <div className={`flex`}>
            <div className="flex-1 text-gray-400">Dob</div>
            <div className="flex-1 text-gray-400">09/11/1990</div>
          </div>
        </div>
        <div className={`px-5 py-1`}>
          <div className={`flex`}>
            <div className="flex-1 text-gray-400">City</div>
            <div className="flex-1 text-gray-400">Karad</div>
          </div>
        </div>
        <div className={`px-5 py-1`}>
          <div className={`flex`}>
            <div className="flex-1 text-gray-400">State</div>
            <div className="flex-1 text-gray-400">Satara</div>
          </div>
        </div>
        <div className={`px-5 py-1`}>
          <div className={`flex`}>
            <div className="flex-1 text-gray-400">Pincode</div>
            <div className="flex-1 text-gray-400">411258</div>
          </div>
        </div>
        <div className={`px-5 py-1`}>
          <div className={`flex`}>
            <div className="flex-1 text-gray-400">Pan</div>
            <div className="flex-1 text-gray-400">BHG22585K</div>
          </div>
        </div>
        <div className={`px-5 pb-5`}>
          <div className={`flex`}>
            <div className="flex-1 text-gray-400">Aadhar</div>
            <div className="flex-1 text-gray-400">9876 5432 1147</div>
          </div>
        </div>
        <div className={`py-5 border-t border-gray-400`}>
          <div className="px-5 mt-1 text-gray-600">Enter Bank Details</div>

          <div className="px-5 my-6 text-gray-500">
            <div className="text-md">Bank Name</div>
            <div className="flex flex-row">
              <input type="text" className="flex-1 bg-gray-100 h-10 rounded-sm p-4 mt-2 focus:bg-white" value="Bank Name" />
            </div>
          </div>
          <div className="px-5 my-6 text-gray-500">
            <div className="text-md">Bank Account Number</div>
            <div className="flex flex-row">
              <input type="text" className="flex-1 bg-gray-100 h-10 rounded-sm p-2 mt-2 focus:bg-white" value="Bank Account Number" />
            </div>
          </div>
          <div className="px-5 my-6 text-gray-500">
            <div className="text-md">IFSC Code</div>
            <div className="flex flex-row">
              <input type="text" className="flex-1 bg-gray-100 h-10 rounded-sm p-2 mt-2 focus:bg-white" value="IFSC Code" />
            </div>
          </div>

          <div className="text-black-900 px-5 my-6 text-gray-900 flex">
            <input type="checkbox" name="" id="" className="mt-2" />
            <div className="flex p=0 ml-2 items-center">
              <div className="flex-1 text-gray-400 rounded-sm focus:bg-white font-xs">
                I have read and accept the <span className={`text-sm text-${props.theme}-500 underline`}>Terms and Conditions</span>
              </div>
            </div>
          </div>

          <div className="px-5 my-6 text-gray-900 flex">
            <button className={`bg-${props.theme}-500 py-2 px-4 w-full rounded-md text-white`}>Next</button>
          </div>
        </div>
      </div>
    </div >
  )
}

export default VerifyDetails
