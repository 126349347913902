import React from "react";
import PropTypes from  "prop-types";

function LightHeroB(props) {
  return (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
        <img className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" />
        <div className="text-center lg:w-2/3 w-full">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            You are pre-approved for a loan upto <br className="hidden lg:inline-block" />
            for Rs. 1,00,000<small>.00</small>
          </h1>
          <p className="mb-8 leading-relaxed">
            You are 2 minutes away from using your credit line.
          </p>
          <div className="flex justify-center">
            <button className={`inline-flex text-white bg-${props.theme}-500 border-0 py-2 px-6 focus:outline-none hover:bg-${props.theme}-600 rounded text-lg`}>
              Begin
            </button>
            
          </div>
        </div>
      </div>
    </section>
  );
}

LightHeroB.defaultProps = {
  theme: 'indigo'
};

LightHeroB.propTypes = {
  theme: PropTypes.string.isRequired
};

export default LightHeroB;
