import React from "react";

function Bank(props) {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-20 mx-auto">
        <div className="text-center mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium text-left title-font text-gray-900 mb-4">
            Select your bank
          </h1>
          <div class="shadow flex">
            <input
              class="w-full rounded p-2"
              type="text"
              placeholder="Search..."
            />
            <button class="bg-white w-auto flex justify-end items-center text-blue-500 p-2 hover:text-blue-400"></button>
          </div>
        </div>
        <div className="p-2 sm:w-1/2 w-full">
          <div className="bg-gray-200 rounded flex p-4 h-full items-center">
            <img
              className="w-8 h-8 flex-shrink-0 mr-4"
              src="https://www.searchpng.com/wp-content/uploads/2019/01/Hdfc-Logo.png" alt="alternate"

            />
            <span className="title-font font-medium">HDFC Bank</span>
          </div>
        </div>
        <div className="p-2 sm:w-1/2 w-full">
          <div className="bg-gray-200 rounded flex p-4 h-full items-center">
            <img
              className="w-10 h-10 flex-shrink-0 mr-4"
              src="https://www.searchpng.com/wp-content/uploads/2019/01/Hdfc-Logo.png" alt="alternate"
            />
            <span className="title-font font-medium">HDFC Bank</span>
          </div>
        </div>
        <div className="p-2 sm:w-1/2 w-full">
          <div className="bg-gray-200 rounded flex p-4 h-full items-center">
            <img
              className="w-8 h-8 flex-shrink-0 mr-4"
              src="https://www.searchpng.com/wp-content/uploads/2019/01/Hdfc-Logo.png" alt="alternate"
            />
            <span className="title-font font-medium">HDFC Bank</span>
          </div>
        </div>
        <div className="p-2 sm:w-1/2 w-full">
          <div className="bg-gray-200 rounded flex p-4 h-full items-center">
            <img
              className="w-8 h-8 flex-shrink-0 mr-4"
              src="https://www.searchpng.com/wp-content/uploads/2019/01/Hdfc-Logo.png" alt="alternate"
            />
            <span className="title-font font-medium">HDFC Bank</span>
          </div>
        </div>
        <div className="p-2 sm:w-1/2 w-full">
          <div className="bg-gray-200 rounded flex p-4 h-full items-center">
            <img
              className="w-8 h-8 flex-shrink-0 mr-4"
              src="https://www.searchpng.com/wp-content/uploads/2019/01/Hdfc-Logo.png" alt="alternate"
            />
            <span className="title-font font-medium">HDFC Bank</span>
          </div>
        </div>
        <div className="p-2 sm:w-1/2 w-full">
          <div className="bg-gray-200 rounded flex p-4 h-full items-center">
            <img
              className="w-8 h-8 flex-shrink-0 mr-4"
              src="https://www.searchpng.com/wp-content/uploads/2019/01/Hdfc-Logo.png" alt="alternate"
            />
            <span className="title-font font-medium">HDFC Bank</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Bank;
