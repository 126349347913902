import React from 'react'

const Repayment = (props) => {
  const getSuccessfulRepaymentItem = ({ amount, date }) => {
    return <div className="flex items-center justify-between py-4 border-t-2">
      <div className={`flex items-center`}>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM15.768 9.14C15.8558 9.03964 15.9226 8.92274 15.9646 8.79617C16.0065 8.6696 16.0227 8.53591 16.0123 8.40298C16.0018 8.27005 15.9648 8.14056 15.9036 8.02213C15.8423 7.90369 15.758 7.79871 15.6555 7.71334C15.5531 7.62798 15.4346 7.56396 15.3071 7.52506C15.1796 7.48616 15.0455 7.47316 14.9129 7.48683C14.7802 7.50049 14.6517 7.54055 14.5347 7.60463C14.4178 7.66872 14.3149 7.75554 14.232 7.86L9.932 13.019L7.707 10.793C7.5184 10.6108 7.2658 10.51 7.0036 10.5123C6.7414 10.5146 6.49059 10.6198 6.30518 10.8052C6.11977 10.9906 6.0146 11.2414 6.01233 11.5036C6.01005 11.7658 6.11084 12.0184 6.293 12.207L9.293 15.207C9.39126 15.3052 9.50889 15.3818 9.63842 15.4321C9.76794 15.4823 9.9065 15.505 10.0453 15.4986C10.184 15.4923 10.32 15.4572 10.4444 15.3954C10.5688 15.3337 10.6791 15.2467 10.768 15.14L15.768 9.14Z" fill="#5FAE5C" />
        </svg>
        <div className="flex flex-col ml-2 items-center">
          <div className={`text-sm text-black`}>{amount}</div>
          <div className={`text-xs text-gray-500`}>{date}</div>
        </div>
      </div>
      <div className={`text-xs text-green-500`}>{amount}</div>
      <div className={`text-xs text-green-500`}>ON TIME</div>
    </div>
  }

  const getLateRepaymentItem = ({ amount, date }) => {
    return <div className="flex items-center justify-between py-4 border-t-2">
      <div className={`flex items-center`}>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22 11C22 13.9174 20.8411 16.7153 18.7782 18.7782C16.7153 20.8411 13.9174 22 11 22C8.08262 22 5.28473 20.8411 3.22183 18.7782C1.15893 16.7153 0 13.9174 0 11C0 8.08262 1.15893 5.28473 3.22183 3.22183C5.28473 1.15893 8.08262 0 11 0C13.9174 0 16.7153 1.15893 18.7782 3.22183C20.8411 5.28473 22 8.08262 22 11ZM11 4.8125C11 4.63016 10.9276 4.4553 10.7986 4.32636C10.6697 4.19743 10.4948 4.125 10.3125 4.125C10.1302 4.125 9.9553 4.19743 9.82636 4.32636C9.69743 4.4553 9.625 4.63016 9.625 4.8125V12.375C9.62504 12.4962 9.65711 12.6152 9.71796 12.72C9.77881 12.8248 9.86627 12.9116 9.9715 12.9718L14.784 15.7218C14.9419 15.8071 15.127 15.8273 15.2996 15.778C15.4722 15.7287 15.6187 15.6139 15.7078 15.458C15.7969 15.3022 15.8214 15.1176 15.7762 14.9439C15.7311 14.7702 15.6197 14.621 15.466 14.5282L11 11.9762V4.8125Z" fill="#E03A46" />
        </svg>
        <div className="flex flex-col ml-2 items-center">
          <div className={`text-sm text-black`}>{amount}</div>
          <div className={`text-xs text-gray-500`}>{date}</div>
        </div>
      </div>
      <div className={`text-xs text-blue-500 underline cursor-pointer`}>PAY NOW</div>
    </div>
  }

  const getNotDueRepaymentItem = ({ amount, date }) => {
    return <div className="flex items-center justify-between py-4 border-t-2">
      <div className={`flex items-center`}>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22 11C22 13.9174 20.8411 16.7153 18.7782 18.7782C16.7153 20.8411 13.9174 22 11 22C8.08262 22 5.28473 20.8411 3.22183 18.7782C1.15893 16.7153 0 13.9174 0 11C0 8.08262 1.15893 5.28473 3.22183 3.22183C5.28473 1.15893 8.08262 0 11 0C13.9174 0 16.7153 1.15893 18.7782 3.22183C20.8411 5.28473 22 8.08262 22 11ZM11 4.8125C11 4.63016 10.9276 4.4553 10.7986 4.32636C10.6697 4.19743 10.4948 4.125 10.3125 4.125C10.1302 4.125 9.9553 4.19743 9.82636 4.32636C9.69743 4.4553 9.625 4.63016 9.625 4.8125V12.375C9.62504 12.4962 9.65711 12.6152 9.71796 12.72C9.77881 12.8248 9.86627 12.9116 9.9715 12.9718L14.784 15.7218C14.9419 15.8071 15.127 15.8273 15.2996 15.778C15.4722 15.7287 15.6187 15.6139 15.7078 15.458C15.7969 15.3022 15.8214 15.1176 15.7762 14.9439C15.7311 14.7702 15.6197 14.621 15.466 14.5282L11 11.9762V4.8125Z" fill="#8C8A8A" />
        </svg>
        <div className="flex flex-col ml-2">
          <div className={`text-sm text-black`}>{amount}</div>
          <div className={`text-xs text-gray-500`}>{date}</div>
        </div>
      </div>
      <div className={`text-xs text-gray-500 cursor-pointer`}>NOT DUE</div>
    </div>
  }

  return props.type === "success" ? getSuccessfulRepaymentItem(props)
    : props.type === "late" ? getLateRepaymentItem(props) :
      props.type === "not due" ? getNotDueRepaymentItem(props) : null
}

export default Repayment
