import React from 'react'

const Congratulations = (props) => {
  return (
    <div className={`min-h-screen bg-${props.theme}-500 text-center flex flex-col py-20`}>
      <div className={`pb-8 flex justify-center items-center`}>
        <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.2169 32.8797L28.3254 41.9882L43.5062 23.7713" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M31.3616 61.7232C48.1299 61.7232 61.7232 48.1299 61.7232 31.3616C61.7232 14.5934 48.1299 1 31.3616 1C14.5934 1 1 14.5934 1 31.3616C1 48.1299 14.5934 61.7232 31.3616 61.7232Z" stroke="white" stroke-width="2" />
        </svg>
      </div>
      <div>
        <p className={`text-3xl font-light pb-2 text-white`}>Congrats</p>
        <p className={`text-white w-9/12 text-center mx-auto`}>
          You are approved for a credit limit upto ₹ 15,000
      </p>
      </div>
    </div>
  )
}

export default Congratulations
