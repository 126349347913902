import React from "react";
import Boxdetail from "./BoxDetail";

function Consent(props) {
  const arr = [
    {
      title: "Secure",
      message: "Encryption helps to protect your personal financial data.",
    },
    {
      title: "Private",
      message: "This application will never be ble to access your credentials.",
    },
  ];
  return (
    <div>
      <br />
      <div className="flex my-4 justify-center content-center my-3">
        <div className="-space-x-4 ">
          <img
            className="relative z-30 inline object-cover w-14 h-14 border-2 border-white rounded-full bg-black-500"
            src="https://console-staging.kdsf.in/favicon.ico"
            alt=""
          />
          <img
            className="relative z-20 inline object-cover w-14 h-14 border-2 border-white rounded-full bg-black-500"
            src="http://pngimg.com/uploads/letter_a/letter_a_PNG19884.png"
            alt="Profile"
          />
          <img
            className="relative z-10 inline object-cover w-14 h-14 border-2 border-white rounded-full bg-black-500"
            src="https://images.pexels.com/photos/2589653/pexels-photo-2589653.jpeg?auto=compress&cs=tinysrgb&h=650&w=940"
            alt="Profile"
          />
        </div>
      </div>
      <br />
      <br />
      <div className="text-center">
        <h3 className="title-font sm:text-4xl text-2xl mb-4 mx-5 font-medium text-gray-900">
          This application uses{" "}
          <span className={`text-${props.theme}-500`}>Kudos</span> to link your
          bank
        </h3>
        <br />
        <br />
      </div>
      {arr.map((ele) => {
        return <Boxdetail title={ele.title} message={ele.message} />;
      })}
      <br />
      <br />

      <div className="text-center mx-7 italic">
        <p className="text-base ">
          By clcking "continue" you agree to{" "}
          <a href="#">kudos finance policy.</a>
        </p>
      </div>
      <br />
      <br />
      <div className="flex justify-center">
        <button
          className={` w-full inline-block  align-middle text-white bg-${props.theme}-500 border-0 mb-4 mx-3 py-3 px-20 focus:outline-none hover:bg-${props.theme}-600 rounded text-lg`}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default Consent;
