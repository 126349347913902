import React from 'react'

function Products(props) {
    const cart = [ {
        _id: "1",
        title: "Product 01",
        images: [
          "https://dummyimage.com/squarepopup",
        ],
        description:
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut od",
        content:
          "ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut od",
  
        price: 101,
        count: 1,
      },
      {
        _id: "2",
        title: "Product 02",
        images: [
           "https://dummyimage.com/squarepopup",
        ],
        description:
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut od",
        content:
          "ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut od",
        price: 102,
        count: 1,
      },
      {
        _id: "2",
        title: "Product 02",
        images: [
          "https://dummyimage.com/squarepopup",
        ],
        description:
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut od",
        content:
          "ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut od",
        price: 102,
        count: 1,
      }]
    return (
        <>
               <header className="text-gray-600 body-font">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a href className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className={`w-10 h-10 text-white p-2 bg-${props.theme}-500 rounded-full`}
            viewBox="0 0 24 24"
          >
            <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
          </svg>
          <span className="ml-3 text-xl">Your Logo</span>
        </a>
        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
          <a href className="mr-5 hover:text-gray-900">First Link</a>
          <a href className="mr-5 hover:text-gray-900">Second Link</a>
          <a href className="mr-5 hover:text-gray-900">Third Link</a>
        </nav>
        <button className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">
          Button
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-4 h-4 ml-1"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </header>
 <div className="w-full flex justify-around flex-wrap my-4 m">
      {cart.map((product) => (
        <div className=" max-w-xs w-full overflow-hidden h-4/5 p-4 m-4 cursor-pointer border-2 " key={product._id}>
          <a href={`/products/${product._id}`}>
            <img className="w-full h-full max-h-80 block object-cover " src={product.images[0]} alt="" />
          </a>
          <div className="box m-4">
            <h3 className="uppercase overflow-hidden whitespace-nowrap overflow-ellipsis" title={product.title}>
              <a href={`/products/${product._id}`}>{product.title}</a>
            </h3 >
            <h4 className="text-black">₹{product.price}</h4>
            <button
              className={`w-full bg-${props.theme}-500 text-white text-center p-2 m-2 cursor-pointer uppercase`}
            >
              Add to cart
            </button>
          </div>
        </div>
      ))}
    </div>
        </>
    )
}

export default Products
