import React from "react";
import PropTypes from "prop-types";
import DragDropImg from "../../../assets/dragdropimage.png"

function LightUploadDocumentsG(props) {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap -mx-4 -mb-10 text-center">
          <div className="sm:w-1/2 mb-10 px-4">
            <div className="rounded-lg h-64 overflow-hidden" style={{ border: "5px dashed darkgray" }}>
              <img
                alt="content"
                className="object-cover object-center h-full w-full"
                src={DragDropImg}
              />
            </div>
            <button className={`flex mx-auto mt-6 text-white bg-${props.theme}-500 border-0 py-2 px-5 focus:outline-none hover:bg-${props.theme}-600 rounded`}>
              Upload Driving License
            </button>
          </div>
          <div className="sm:w-1/2 mb-10 px-4">
            <div className="rounded-lg h-64 overflow-hidden" style={{ border: "5px dashed darkgray" }}>
              <img
                alt="content"
                className="object-cover object-center h-full w-full"
                src={DragDropImg}
              />
            </div>
            <button className={`flex mx-auto mt-6 text-white bg-${props.theme}-500 border-0 py-2 px-5 focus:outline-none hover:bg-${props.theme}-600 rounded`}>
              Upload PAN Card
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

LightUploadDocumentsG.defaultProps = {
  theme: 'indigo'
};

LightUploadDocumentsG.propTypes = {
  theme: PropTypes.string.isRequired
};

export default LightUploadDocumentsG;
