import React from 'react'

const Decline = (props) => {
  return (
    <div className={`min-h-screen bg-${props.theme}-500 text-center flex flex-col py-20`}>
      <div className={`py-10 flex justify-center items-center`}>
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="29.5" fill="white" stroke="#E0E0E0" />
          <path d="M40.6496 15.6522L30.3478 25.954L20.0461 15.6522L16 19.6983L26.3017 30L16 40.3018L20.0461 44.3479L30.3478 34.0461L40.6496 44.3479L44.6957 40.3018L34.3939 30L44.6957 19.6983L40.6496 15.6522Z" fill="#0652AD" />
        </svg>
      </div>
      <div>
        <p className={`text-white w-9/12 text-center mx-auto`}>
          We are unable to extend a credit limit at the moment. We are always reviewing our
          policies and will notify you of changes. Stay tight!
      </p>
      </div>
    </div>
  )
}

export default Decline
