import React from 'react'
import { AiFillLock } from 'react-icons/ai';

function SignIn(props) {
    return (
        <>
        <section className="text-gray-600 body-font">


      <div className="container px-5 py-24 mx-auto flex items-center md:flex-row flex-col">
        <div className="flex flex-col mb-6 pr-0 w-full md:w-auto md:text-left ">

        <div className="p-2 sm:w-1/2 w-full mb-8">
          <div className="bg-gray-200 rounded flex p-4 h-full items-center">
            <img
              className="w-8 h-8 flex-shrink-0 mr-4"
              src="https://www.searchpng.com/wp-content/uploads/2019/01/Hdfc-Logo.png" alt="alternate"

            />
            <span className="title-font font-medium">HDFC Bank</span>
          </div>
        </div>
          <h1 className="md:text-3xl text-2xl font-medium title-font text-gray-900">
            Enter your credentials
          </h1>

          <div className="relative flex-grow w-full">       
            <input
              type="text"
              placeholder ="Username"
              id="full-name"
              name="full-name"
              className={`w-full my-4 bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-${props.theme}-500 focus:bg-transparent focus:ring-2 focus:ring-${props.theme}-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`}
            />

       
            <input
              type="text"
              placeholder="Password"
              id="full-name"
              name="full-name"
              className={`w-full my-4 bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-${props.theme}-500 focus:bg-transparent focus:ring-2 focus:ring-${props.theme}-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`}
            />


          </div>
         
          <button className={`text-white md:flex md:justify-center md:items-center bg-${props.theme}-500 border-0 my-5  py-2  px-8 focus:outline-none hover:bg-${props.theme}-600 rounded text-lg inline-flex items-center`}>
          <AiFillLock className=""/>
          <span className="ml-24">Continue</span>
          </button>
         

        </div>
        

      </div>
    </section>
        </>
    )
}

export default SignIn
