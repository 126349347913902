import React from "react"

function EMID() {
  return (
    <svg viewBox="0 0 266 150" fill="none">
      <path fill="var(--solid)" d="M0 0h266v150H0z" />
      <rect
        x={84}
        y={20}
        width={70}
        height={5}
        rx={2.5}
        fill="var(--solid-900)"
      />
      <rect
        x={84}
        y={29}
        width={145}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect x={20} y={26} width={12} height={2} rx={1} fill="var(--base-500)" />
      <rect
        x={84}
        y={35}
        width={129}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={84}
        y={41}
        width={18}
        height={3}
        rx={1.5}
        fill="var(--main-500)"
      />
      <path
        d="M245.5 53a.5.5 0 010 1h-225a.5.5 0 010-1h225zM245.5 96a.5.5 0 010 1h-225a.5.5 0 010-1h225z"
        fill="var(--base-300)"
      />
      <rect
        x={20}
        y={20}
        width={23}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <rect
        x={84}
        y={63}
        width={70}
        height={5}
        rx={2.5}
        fill="var(--solid-900)"
      />
      <rect
        x={84}
        y={72}
        width={145}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect x={20} y={69} width={12} height={2} rx={1} fill="var(--base-500)" />
      <rect
        x={84}
        y={78}
        width={129}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={84}
        y={84}
        width={18}
        height={3}
        rx={1.5}
        fill="var(--main-500)"
      />
      <rect
        x={20}
        y={63}
        width={23}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
      <rect
        x={84}
        y={106}
        width={70}
        height={5}
        rx={2.5}
        fill="var(--solid-900)"
      />
      <rect
        x={84}
        y={115}
        width={145}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={20}
        y={112}
        width={12}
        height={2}
        rx={1}
        fill="var(--base-500)"
      />
      <rect
        x={84}
        y={121}
        width={129}
        height={3}
        rx={1.5}
        fill="var(--base-500)"
      />
      <rect
        x={84}
        y={127}
        width={18}
        height={3}
        rx={1.5}
        fill="var(--main-500)"
      />
      <rect
        x={20}
        y={106}
        width={23}
        height={3}
        rx={1.5}
        fill="var(--solid-900)"
      />
    </svg>
  )
}

export default EMID;
