import React from 'react'

const Screen3 = (props) => {
  return (
    <div className="bg-white min-h-screen">

      <div className="m-auto w-11/12 bg-white rounded-md py-5">
        <div className="text-xs text-gray-400 my-2">Your Proof of Identity is Verified</div>
        <div className="text-md font-bold">Verifying Proof of Address</div>
      </div>

      <div className="m-auto w-11/12 bg-white rounded-md p-5 text-black-900 bg-gray-100 flex justify-between px-5 mb-4">
        <div className="font-sm">PROOF OF IDENTITY</div>
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.19995 13.6L11.8 17.2L17.8 10" stroke="#4CAF50" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z" stroke="#4CAF50" stroke-width="2" />
        </svg>
      </div>

      <div className="m-auto w-11/12 bg-white rounded-md p-5" style={{ border: "1px solid #9A9A9A" }}>
        <div className="px-5 mt-1 text-gray-900">PROOF OF ADDRESS</div>
        <div className="px-5 my-6 text-gray-500">
          <div className="text-md">Enter Aadhar Number</div>
          <div className="flex flex-row">
            <input type="text" className="flex-1 bg-gray-100 h-10 rounded-sm p-4 mt-2 focus:bg-white" value="1234 5678 9012" />
          </div>
        </div>
        <div className="px-5 my-6 text-gray-500">
          <div className="text-md">Enter the captcha shown below</div>
          <div className="flex flex-row">
            <input type="text" className="flex-1 bg-gray-100 h-10 rounded-sm p-2 mt-2 focus:bg-white" value="captcha" />
          </div>
        </div>

        <div className="px-5 my-6 text-gray-500">
          <div className="pl-2 text-xl text-black font-bold" style={{
            fontFamily: "Niconne,cursive",
          }}>36hj25</div>
        </div>

        <div className="px-5 my-6 text-gray-900 flex">
          <button className={`bg-${props.theme}-500 py-2 px-4 w-full rounded-md text-white`}>Next</button>
        </div>
      </div>
    </div >
  )
}

export default Screen3
