import React from 'react'

const Screen1 = (props) => {
  return (
    <div className={`overflow-hidden bg-${props.theme}-500 p-10`}>
      <div className="flex items-center justify-center w-full mx-auto space-x-3">
        <div className="flex flex-col text-center">
          <span className="text-white mb-5 text-center">Complete your KYC to avail credit limit upto ₹ 15000</span>
          <div>
            <button className={`bg-white text-${props.theme}-500 w-40 py-2 px-4 rounded-md`} type="button">
              Apply Now
          </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Screen1
